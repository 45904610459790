<template>
  <div class="accordion" :class="{ 'is-open': isOpen }">
    <div class="accordion--header" @click.stop="toggleAccordion">
      <slot name="header"></slot>
    </div>
    <transition name="slide-fade">
      <div class="accordion--body">
        <div class="accordion--content">
          <slot name="content"></slot>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'Accordion',
  props: ['expanded'],
  methods: {
    toggleAccordion () {
      this.isOpen = !this.isOpen
    },
    setExpanded (state) {
      this.isOpen = state
    }
  },
  created () {
    if (this.expanded) {
      this.setExpanded(this.expanded)
    }
  },
  data () {
    return {
      isOpen: false
    }
  }
}
</script>
