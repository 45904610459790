<template>
  <div class="loaderForRelatedProducts">
    <div class="lds-dual-ring"/>
  </div>
</template>

<script>
export default {
  name: 'loaderForRelatedProducts'
}
</script>
