<template>
  <Modal
    :showModal="showModal"
    @close="$emit('close')"
    class="modal-download-resources"
  >
    <template v-slot:body>
      <div v-if="!isInitialized" class="modal-download-resources__wrap">
        <i class="icon-spinner modal-download-resources__icon"></i>
        <h3 class="text-h4 modal-download-resources__title modal-download-resources__title--inline">Please wait, preparing to download your file.</h3>
      </div>
      <template v-else>
        <div v-if="downloadLink && !showError" class="modal-download-resources__wrap">
          <h3 class="text-h4 modal-download-resources__title">Your file is ready for download</h3>
          <a
            class="button button--primary modal-download-resources__button"
            :href="downloadLink"
            target="_blank"
          >
            Download file
          </a>
        </div>
        <div v-else class="modal-download-resources__wrap">
          <h3 class="text-h4 modal-download-resources__title">Sorry, something went wrong</h3>
        </div>
      </template>
    </template>
  </Modal>
</template>

<script>
import { mapActions } from 'vuex'

import Modal from '@/components/modal/Modal'

export default {
  name: 'ModalAddressValidation',
  components: {
    Modal
  },
  props: ['showModal', 'resourceType', 'productCode'],
  methods: {
    ...mapActions('product', ['getDownloadAllResourcesByTypeLink'])
  },
  created () {
    this.getDownloadAllResourcesByTypeLink({
      productCode: this.productCode,
      resourceType: this.resourceType
    }).then(link => {
      this.downloadLink = link
    }).catch(() => {
      this.showError = true
    }).finally(() => {
      this.isInitialized = true
    })
  },
  data () {
    return {
      downloadLink: null,
      showError: false,
      isInitialized: false
    }
  }
}
</script>
