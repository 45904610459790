var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sticky-tabs"},[_c('div',{staticClass:"sticky-tabs__nav",class:{
      'sticky-tabs__nav--ie': _vm.isIE,
      'sticky-tabs__nav--ie-sticky': _vm.isIE && _vm.emulateStickyBehavior,
      'sticky-tabs__nav--shadow': _vm.emulateStickyBehavior
    },style:({
      top: _vm.isIE
        ? _vm.emulateStickyBehavior ? (_vm.headerHeight + "px") : '0'
        : (_vm.headerHeight + "px")
    })},[_vm._t("nav"),(_vm.emulateStickyBehavior)?_vm._t("cta"):_vm._e()],2),_c('div',{staticClass:"sticky-tabs__main"},[_vm._t("main")],2)])}
var staticRenderFns = []

export { render, staticRenderFns }