<template>
  <div class="products-comparison products-compare-section" v-if="comparisonData">
    <div class="products-comparison__products">
      <div
        ref="productsTable"
        class="table table--products"
        :class="[comparisonData.products.length > 4 ? 'table--scrollable' : `table--${comparisonData.products.length}`]"
      >
        <div
          class="table__col table__col--name"
          :style="{ width: `${firstColumnWidth}px` }"
        >
          <FormToggle
            v-model="showDifferencesOnly.value"
            :field="showDifferencesOnly"
          />
        </div>
        <div
          ref="productsScroll"
          class="table__scrollable-block"
          :style="comparisonData.products.length > 4 ? {flexBasis: `${productColumnWidth}px`} : null"
        >
          <div
            v-for="(product, idx) in comparisonData.products"
            :key="product.sku"
            class="table__col table__col--content"
            :class="{ 'table__col--current': idx === 0 }"
          >
            <div class="products-comparison__label">
              <span v-if="idx === 0">Current Product</span>
            </div>
            <div
              class="products-comparison__products-item"
              :class="{ 'products-comparison__products-item--ie': isIE }"
            >
              <CarouselProduct
                :productData="product.data"
                :isPriceVisible="false"
                :isFavoriteVisible="true"
                :showProductTitle="false"
                :showProductCatalogId="false"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="products-comparison__specifications">
      <div
        class="products-comparison__specifications-item"
        v-for="specification in comparisonData.specifications"
        v-show="specification.hasDifferences || !showDifferencesOnly.value || specification.name === 'General'"
        :key="specification.name"
      >
        <h3 class="text-h4 products-comparison__specifications-title" v-html="specification.name"></h3>
        <div class="products-comparison__specifications-table-wrap">
          <div
            v-if="specification.list"
            class="table table--specification"
            :class="[
              comparisonData.products.length > 4 ? 'table--scrollable' : `table--${comparisonData.products.length}`,
              isSpesificationsDragging ? 'dragging' : null
            ]"
          >
            <template v-if="specification.name === 'General'">
              <div class="table__row">
                <div
                  class="table__col table__col--name"
                  :style="{ width: `${firstColumnWidth}px` }"
                >Name</div>
                <div
                  ref="specificationScroll"
                  class="table__scrollable-block"
                  :style="comparisonData.products.length > 4 ? {flexBasis: `${productColumnWidth}px`} : null"
                  @[specificationsScrollEvents.down]="handleSpecificationScrollMousedown"
                >
                  <div
                    v-for="product in products"
                    :key="product.sku"
                    class="table__col table__col--content"
                  >
                    <router-link
                      :to="product.link"
                      v-html="product.title"
                      class="products-comparison__blue-link"
                    ></router-link>
                  </div>
                </div>
              </div>
              <div class="table__row">
                <div
                  class="table__col table__col--name"
                  :style="{ width: `${firstColumnWidth}px` }"
                >Catalog ID</div>
                <div
                  ref="specificationScroll"
                  class="table__scrollable-block"
                  :style="comparisonData.products.length > 4 ? {flexBasis: `${productColumnWidth}px`} : null"
                  @[specificationsScrollEvents.down]="handleSpecificationScrollMousedown"
                >
                  <div
                    v-for="product in products"
                    :key="product.sku"
                    class="table__col table__col--content"
                  >
                    <router-link
                      v-if="product.catalogId"
                      :to="product.link"
                      v-html="product.catalogId"
                      class="products-comparison__blue-link"
                    ></router-link>
                    <span v-else>-</span>
                  </div>
                </div>
              </div>
            </template>
            <div
              v-for="specificationValue in specification.list"
              v-show="specificationValue.hasDifferences || !showDifferencesOnly.value"
              :key="specificationValue.name"
              class="table__row"
              :class="{'highlight': checkRowValuesAreDifferent(specificationValue.list)}"
            >
              <div
                class="table__col table__col--name"
                :style="{ width: `${firstColumnWidth}px` }"
              >{{ specificationValue.name }}</div>
              <div
                ref="specificationScroll"
                class="table__scrollable-block"
                :style="comparisonData.products.length > 4 ? {flexBasis: `${productColumnWidth}px`} : null"
                @[specificationsScrollEvents.down]="handleSpecificationScrollMousedown"
              >
                <div
                  v-for="(value, idx) in specificationValue.list"
                  :key="idx"
                  v-html="value || '-'"
                  class="table__col table__col--content"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import _ from 'lodash'

import ScreenWidthMixin from '@/mixins/ScreenWidthMixin'
import UtilityMixin from '@/mixins/UtilityMixin'
import CarouselProduct from '@/components/CarouselProduct'
import FormToggle from '@/components/FormToggle'

export default {
  name: 'ProductsComparison',
  components: {
    CarouselProduct,
    FormToggle
  },
  mixins: [ScreenWidthMixin, UtilityMixin],
  props: {
    products: {
      type: Array,
      required: true
    }
  },
  computed: {
    ...mapState('user', ['favoriteProductsCodes']),
    ...mapGetters('user', ['isGuest']),
    firstColumnWidth () {
      return this.isDesktop ? 240 : 140
    }
  },
  methods: {
    ...mapActions('productsComparison', ['constructSpecifications']),
    ...mapActions('user', ['setFavoriteProductsCodes']),
    checkRowValuesAreDifferent (valuesObject) {
      const values = Object.values(valuesObject)
      return values?.some(el => el !== values[0])
    },
    hanleProductsScroll: _.throttle(function (e) {
      const currentScrollPosition = e.target.scrollLeft
      this.$refs.specificationScroll.forEach(ref => {
        ref.scrollLeft = currentScrollPosition
      })
    }, 15),
    handlePageResize: _.throttle(function () {
      const visibleItemsAmount = this.isMobile ? 3 : 4
      const sizeMakeRightBorderVisible = this.isDesktop ? 0.5 : 0
      const calculatedWidth = ((this.$refs.productsTable?.clientWidth - this.firstColumnWidth) / visibleItemsAmount) - sizeMakeRightBorderVisible
      this.productColumnWidth = calculatedWidth || this.firstColumnWidth
    }, 15),
    handleSpecificationScrollMousedown (e) {
      this.isSpesificationsDragging = true
      this.mousePosition = {
        left: this.$refs.specificationScroll[0].scrollLeft,
        x: this.isTouchScreen ? e.targetTouches[0].clientX : e.clientX
      }
      document.addEventListener(this.specificationsScrollEvents.move, this.handleMouseMove)
      document.addEventListener(this.specificationsScrollEvents.up, this.handleSpecificationScrollMouseup)
    },
    handleSpecificationScrollMouseup () {
      this.isSpesificationsDragging = false
      document.removeEventListener(this.specificationsScrollEvents.move, this.handleMouseMove)
      document.removeEventListener(this.specificationsScrollEvents.up, this.handleSpecificationScrollMouseup)
    },
    handleMouseMove: _.throttle(function (e) {
      const clientX = this.isTouchScreen ? e.targetTouches[0].clientX : e.clientX
      const dx = clientX - this.mousePosition.x
      const scrollLeft = this.mousePosition.left - dx
      this.$refs.specificationScroll.forEach(ref => {
        ref.scrollLeft = scrollLeft
      })
      this.$refs.productsScroll.scrollLeft = scrollLeft
    }, 15)
  },
  async created () {
    const productsData = this.products.map(product => ({
      code: product.sku,
      data: product
    }))
    const specifications = await this.constructSpecifications(this.products)
    this.comparisonData = {
      products: productsData,
      specifications
    }

    this.specificationsScrollEvents = {
      down: this.isTouchScreen ? 'touchstart' : 'mousedown',
      up: this.isTouchScreen ? 'touchend' : 'mouseup',
      move: this.isTouchScreen ? 'touchmove' : 'mousemove'
    }

    this.$nextTick(() => {
      if (this.$refs.productsScroll) this.$refs.productsScroll.addEventListener('scroll', this.hanleProductsScroll)
      if (this.$refs.productsTable) {
        this.handlePageResize()
        window.addEventListener('resize', this.handlePageResize)
      }
    })

    if (!this.isGuest && !this.favoriteProductsCodes) this.setFavoriteProductsCodes()
  },
  beforeDestroy () {
    if (this.$refs.productsScroll) this.$refs.productsScroll.removeEventListener('scroll', this.hanleProductsScroll)
    if (this.$refs.productsTable) window.removeEventListener('resize', this.handlePageResize)
  },
  data () {
    return {
      comparisonData: null,
      showDifferencesOnly: {
        type: 'checkbox',
        label: 'Show differences only',
        value: true
      },
      productColumnWidth: 250,
      mousePosition: { left: 0, x: 0 },
      isSpesificationsDragging: false,
      specificationsScrollEvents: {
        down: 'mousedown',
        up: 'mouseup',
        move: 'mousemove'
      }
    }
  }
}
</script>
