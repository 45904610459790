<template>
  <Modal
    @close="$emit('close')"
    :showModal="showModal"
    :isLoading="!isInitialized"
    class="modal-inventory-check"
  >
    <template v-slot:header v-if="isInitialized">
      Check Inventory
    </template>
    <template v-slot:body v-if="isInitialized">
      <div v-if="inventoryData && !inventoryData.failure">
        <h3 class="text-h3 modal-inventory-check__title" v-html="productData.title"></h3>
        <p>{{ `Inventory as of ${new Date()}` }}</p>
        <ul class="modal-inventory-check__table">
          <li class="modal-inventory-check__table-row modal-inventory-check__table-row--title">
            <span>Distribution Center</span>
            <span>Units Available</span>
          </li>
          <template v-if="inventoryData.items && inventoryData.items.length">
            <li
              class="modal-inventory-check__table-row"
              v-for="centers in inventoryData.items"
              :key="centers.name"
            >
              <span>{{ centers.name }}</span>
              <span>{{ centers.count }}</span>
            </li>
          </template>
          <li
            class="modal-inventory-check__table-row"
            v-else
          >
            <span>-</span>
            <span>-</span>
          </li>
          <li class="modal-inventory-check__table-row modal-inventory-check__table-row--footer">
            <span>Total</span>
            <span>{{ inventoryData.total }}</span>
          </li>
        </ul>
      </div>
      <h3 v-else class="text-h4">Something went wrong</h3>
    </template>
  </Modal>
</template>

<script>
import { mapActions } from 'vuex'

import Modal from '@/components/modal/Modal'

export default {
  name: 'ModalInventoryCheck',
  components: {
    Modal
  },
  props: ['showModal', 'productData'],
  methods: {
    ...mapActions('cart', ['checkInventory'])
  },
  created () {
    this.checkInventory({ code: this.productData?.code })
      .then(({ inventoryData }) => {
        this.inventoryData = inventoryData
      }).finally(() => {
        this.isInitialized = true
      })
  },
  data () {
    return {
      inventoryData: null,
      isInitialized: false
    }
  }
}
</script>
