<template>
  <div class="sticky-tabs">
    <div
      class="sticky-tabs__nav"
      :class="{
        'sticky-tabs__nav--ie': isIE,
        'sticky-tabs__nav--ie-sticky': isIE && emulateStickyBehavior,
        'sticky-tabs__nav--shadow': emulateStickyBehavior
      }"
      :style="{
        top: isIE
          ? emulateStickyBehavior ? `${headerHeight}px` : '0'
          : `${headerHeight}px`
      }"
    >
      <slot name="nav"></slot>
      <slot v-if="emulateStickyBehavior" name="cta"></slot>
    </div>
    <div class="sticky-tabs__main">
      <slot name="main"></slot>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'

import NavigationalMixin from '@/mixins/NavigationalMixin'
import UtilityMixin from '@/mixins/UtilityMixin'

export default {
  mixins: [NavigationalMixin, UtilityMixin],
  methods: {
    scrollListener: _.throttle(function () {
      this.headerHeight = this.getHeaderHeight()
      const fromTop = Math.floor(window.pageYOffset)

      document.querySelectorAll('.sticky-tabs__nav a').forEach(link => {
        const section = document.querySelector(link.hash)
        const sectionOffset = Math.floor(section.getBoundingClientRect().top + fromTop - this.headerHeight - 20)

        if (
          sectionOffset <= fromTop &&
          sectionOffset + section.offsetHeight > fromTop
        ) {
          link.classList.add('current')
        } else {
          link.classList.remove('current')
        }
      })

      const navBoundingClientRect = document.querySelector('.sticky-tabs').getBoundingClientRect()
      this.emulateStickyBehavior = navBoundingClientRect.top - this.headerHeight <= 0 &&
        navBoundingClientRect.height + navBoundingClientRect.top >= 0
    }, 100),
    navigateToSection (e) {
      e.preventDefault()
      const sectionPosition = document.querySelector(e.target.hash)?.getBoundingClientRect().top + window.pageYOffset - 15
      const headerHeight = document.querySelector('#header')?.clientHeight || 0
      const positionToScroll = typeof headerHeight === 'number' ? sectionPosition - headerHeight : sectionPosition
      this.scrollToPosition(positionToScroll)
    }
  },
  mounted () {
    window.addEventListener('scroll', this.scrollListener)

    document.querySelectorAll('.sticky-tabs__nav a').forEach(link => {
      link.addEventListener('click', this.navigateToSection)
    })

    if (!this.isMobile && this.isIE) {
      this.$nextTick(() => {
        const nav = document.querySelector('.sticky-tabs__nav')
        if (nav) nav.style.width = `${nav?.offsetWidth}px`
      })
    }
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.scrollListener)
  },
  data () {
    return {
      emulateStickyBehavior: false,
      headerHeight: 0
    }
  }
}
</script>
